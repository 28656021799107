import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "hiddenInput"];

  async submit(event) {
    event.preventDefault();

    this.hiddenInputTarget.value = event.target.textContent.startsWith("Hide") ? "true" : "false";
    const formData = new FormData(this.formTarget);
    formData.append("_method", "PATCH");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    const response = await fetch(this.formTarget.action, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData,
    });

    console.log("response", response);

    if (response.ok) {
      console.log("response ok");
      this.element.classList.toggle("message-hidden");
    }
  }
}
