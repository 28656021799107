import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  openOrDownload(event) {
    const actionUrl = event.currentTarget.dataset.actionLink;
    const downloadable = event.currentTarget.dataset.actionDownload;

    if (actionUrl) {
      if (downloadable === "true") {
        this.downloadFile(actionUrl);
      } else {
        document.location.href = actionUrl;
      }
    }
  }

  downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = true;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
