/* global Survey, survey, showdown, $ */
import { Controller } from "@hotwired/stimulus";
import handleComplete from "./form_events/handleComplete";
import handlePartialSend from "./form_events/handlePartialSend";
import handleValueChanged from "./form_events/handleValueChanged";
import handleAfterRenderQuestion from "./form_functions/handleAfterRenderQuestion";

export default class extends Controller {
  connect() {
    
    this._renderForm(this.data.get("form-data"), this.data.get("response-url"));

    window.onload = () => {
      this._moveElements();
    };

    if (this.data.get("admin") === "true") {
      window.addEventListener("beforeunload", this.beforeUnloadHandler);
    }
  }

  beforeUnloadHandler = (event) => {
    event.preventDefault();
    event.returnValue = true;
  };

  _renderForm(surveyJSON, _url) {
    Survey.StylesManager.applyTheme("defaultV2");
    window.survey = new Survey.Model(surveyJSON);

    if(this.isDealStage()){
      window.survey.completedHtml = this.dealCompletedHtml();
    }

    // this one should be from response data rather than local storage
    var responseData = this.data.get("response-data");
    if (responseData && responseData !== "null") {
      var data = JSON.parse(responseData);
      survey.data = data;
      if (data.pageNo) {
        survey.currentPageNo = data.pageNo;
      }
    }

    this._renderMarkdown();

    $("#surveyContainer").Survey({
      model: survey,
      sendResultOnPageNext: true,
      onPartialSend: handlePartialSend,
      onValueChanged: handleValueChanged,
      onComplete: handleComplete,
      onAfterRenderQuestion: handleAfterRenderQuestion,
    });
  }

  _moveElements() {
    // Move header within the survey.js questions element.
    const header = document.querySelector(".bare-form-header");
    const headerLocation = document.querySelector(".sd-body--responsive");
    if (headerLocation && headerLocation.firstChild)
      headerLocation.insertBefore(header, headerLocation.firstChild);

    // Move Bare logo within the sidebar element.
    const logo = document.querySelector(".bare-logo");
    const mobileLogo = document.querySelector(".mobile-navigation > .bare-logo");
    if (logo) logo.style.display = "block";
    if (mobileLogo) mobileLogo.style.display = "block";
    const logoLocation = document.querySelector(".sd-progress-buttons__container");

    if (logoLocation && logoLocation.firstChild)
      logoLocation.insertBefore(logo, logoLocation.firstChild);
  }

  _renderMarkdown() {
    //Create showdown markdown converter
    var converter = new showdown.Converter({ openLinksInNewWindow: true });
    window.survey.onTextMarkdown.add(function (survey, options) {
      //convert the markdown text to html
      var str = converter.makeHtml(options.text);
      //remove root paragraphs <p></p>
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      //set html
      options.html = str;
    });
  }

  isDealStage(){
    const surveyContainer = document.getElementById("surveyContainer");
    const dashboardStage = JSON.parse(
      surveyContainer.getAttribute("data-default-response")
    )['dashboard_stage'];

    return dashboardStage == 'deal';
  }

  dealCompletedHtml(){
    return `
        <h3>Thank you for completing your paperwork ahead of time</h3>
        <p>
          This will save you time when your loved one passes. At that time we will ask you 
          to return to your dashboard to review your answers and provide additional information 
          on the passing.
        </p>
        <p>
          We are here to support you and your family throughout this time, so please don’t hesitate 
          to call or email if you have any questions.
        </p>
        <p>
          For now, you can click on the button below to return to your dashboard.
        </p>
      `;
  }
}
