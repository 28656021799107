import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.navElement = this.element.querySelector(".pagy");
    this.prevPageElement = this.navElement.querySelector('[aria-label="Previous"]');
    this.nextPageElement = this.navElement.querySelector('[aria-label="Next"]');

    this.replaceIcon(this.prevPageElement, "&lsaquo;");
    this.replaceIcon(this.nextPageElement, "&rsaquo;");
    this.addFirstAndLastButtons();
  }

  replaceIcon(element, icon) {
    if (element) element.innerHTML = icon;
  }

  addFirstAndLastButtons() {
    const firstPageLink = this.prevPageElement.nextElementSibling;
    const lastPageLink = this.nextPageElement.previousElementSibling;

    const firstPageHref = firstPageLink ? firstPageLink.href : "#";
    const lastPageHref = lastPageLink ? lastPageLink.href : "#";

    const isPrevDisabled = this.prevPageElement.getAttribute("aria-disabled") === "true";
    const isNextDisabled = this.nextPageElement.getAttribute("aria-disabled") === "true";

    const firstButton = this.createPageButton("&laquo;", firstPageHref, isPrevDisabled);
    const lastButton = this.createPageButton("&raquo;", lastPageHref, isNextDisabled);

    this.navElement.insertBefore(firstButton, this.navElement.firstChild);
    this.navElement.appendChild(lastButton);
  }

  createPageButton(icon, href, isDisabled) {
    const button = document.createElement("span");
    button.innerHTML = isDisabled
      ? `<a>${icon}</a>`
      : `<a href="${href}" data-turbo-action="advance">${icon}</a>`;
    return button;
  }

  reloadPartial(event) {
    event.preventDefault();
    const url = new URL(event.currentTarget.href);
    const partialName = url.searchParams.get("partial");

    const customEvent = new CustomEvent("partial_navigation:loadPartial", {
      detail: { partialName: partialName },
    });

    history.pushState(null, "", url);
    document.dispatchEvent(customEvent);
  }
}
