import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "variant", "form"];

  connect() {
    let modifier = this.element.querySelector("input[name=products-modifier]");
    if (modifier) {
      this.setEngraving(modifier?.id, modifier?.value);
    }
  }

  async submit(event) {
    event.preventDefault();
    this.setQuantity();

    const button = event.currentTarget;

    button.disabled = true;
    button.form.submit();

    setTimeout(() => {
      button.disabled = false;
    }, 2000);
  }

  updateEngraving(event) {
    this.setEngraving(event.target.id, event.target.value);
  }

  selectProduct(event) {
    const item = event.currentTarget.querySelector("input");
    item.checked = true;
    this.updateVisibility(this.itemTargets, item.value);
  }

  selectVariant(event) {
    const variant = event.currentTarget.querySelector("input");
    variant.checked = true;
    this.updateVisibility(this.variantTargets, variant.value);
  }

  updateVisibility(targets, selectedSku) {
    targets.forEach((element) => {
      element.classList.toggle("hidden", element.dataset.sku !== selectedSku);
    });
  }

  setEngraving(id, engraving) {
    let name = "product_modifier-" + id;
    this.formTargets.forEach((element) => {
      if (element.querySelector(`input[name=${name}]`)) {
        element.querySelector(`input[name=${name}]`).value = engraving;
      } else {
        element.insertAdjacentHTML(
          "afterbegin",
          `<input type="hidden" name=${name} value="${engraving}" />`
        );
      }
    });
  }

  setQuantity() {
    let quantity = this.element.querySelector("input[name=product-quantity]");
    if (quantity) {
      this.formTargets.forEach((element) => {
        if (element.querySelector(`input[name=quantity]`)) {
          element.querySelector("input[name=quantity]").value = quantity?.value;
        } else {
          element.insertAdjacentHTML(
            "afterbegin",
            `<input type="hidden" name="quantity" value="${quantity?.value}" />`
          );
        }
      });
    }
  }
}
