import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "guestQuestion",
    "yesRadio",
    "guestInput",
    "form",
    "thankYouMessage",
    "formContainer",
    "deleteModal",
    "title",
    "subTitle",
  ];

  connect() {
    this.toggleGuestQuestion();
  }

  toggleGuestQuestion() {
    if (this.hasYesRadioTarget && this.yesRadioTarget.checked) {
      this.guestQuestionTarget.classList.remove("hidden");
      this.guestInputTarget.setAttribute("required", "required");
    } else if (this.hasGuestQuestionTarget) {
      this.guestQuestionTarget.classList.add("hidden");
      this.guestInputTarget.removeAttribute("required");
    }
  }

  async submit(event) {
    event.preventDefault();
    const formData = new FormData(this.formTarget);
    const response = await fetch(this.formTarget.action, {
      method: "POST",
      body: formData,
      headers: { Accept: "application/json" },
    });

    if (response.ok) {
      this.formContainerTarget.classList.add("hidden");
      this.thankYouMessageTarget.classList.remove("hidden");

      if (this.hasTitleTarget) {
        this.titleTarget.innerHTML = "RSVP Added";
      }
      if (this.hasSubTitleTarget) {
        this.subTitleTarget.innerHTML = "We've added this RSVP to the list.";
      }
    }
  }

  addAnotherRsvp() {
    this.formContainerTarget.classList.remove("hidden");
    this.thankYouMessageTarget.classList.add("hidden");

    if (this.hasTitleTarget) {
      this.titleTarget.innerHTML = "Add an RSVP";
    }
    if (this.hasSubTitleTarget) {
      this.subTitleTarget.innerHTML =
        "You can use the form below to add an RSVP response on behalf of someone else. You can use this to track RSVP responses from those who’ve responded in person.";
    }
  }

  async deleteRsvp(event) {
    event.preventDefault();

    const csrfToken = document.querySelector("meta[name='csrf-token']").content;
    const response = await fetch(this.deleteModalTarget.dataset.deleteActionPath, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "application/json",
      },
    });

    if (response.ok) {
      this.reloadPartial(event);
    } else {
      console.error("Failed to delete RSVP:", response);
    }
  }

  reloadPartial(event) {
    event.preventDefault();
    const close = document.querySelector(
      `#rsvp_delete-${this.deleteModalTarget.dataset.uniqueId}-close-button`
    );
    close.click();
  }
}
