import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const openMemoryModal = urlParams.get("open_memory_modal");
    const openCondolenceModal = urlParams.get("open_condolence_modal");

    if (openMemoryModal === "true") {
      await this.openModal("modal-custom_memory", "open_memory_modal");
    }

    if (openCondolenceModal === "true") {
      await this.openModal("modal-condolence", "open_condolence_modal");
    }
  }

  async openModal(selector, param) {
    await this.waitForHSOverlay();
    console.log(`#${selector}`);
    const modalInstance = window.HSOverlay.getInstance(`#${selector}`);
    if (modalInstance) {
      window.HSOverlay.open(`#${selector}`);
      this.clearUrlParam(param);
    }
  }

  clearUrlParam(param) {
    const url = new URL(window.location.href);
    url.searchParams.delete(param);
    window.history.replaceState({}, document.title, url);
  }

  waitForHSOverlay() {
    const maxAttempts = 20;

    return new Promise((resolve, reject) => {
      let attempts = 0;
      const interval = setInterval(() => {
        if (window.HSOverlay && window.HSOverlay.getInstance) {
          clearInterval(interval);
          resolve();
        } else if (attempts >= maxAttempts) {
          clearInterval(interval);
          reject("HSOverlay failed to load.");
        }
        attempts++;
      }, 2000);
    });
  }
}
