import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    timeout: Boolean,
    title: String,
  };

  connect() {
    document.addEventListener("turbo:load", () => {
      this.closeModal("#modal-loading");
    });

    const buttons = this.element.querySelectorAll("button");
    buttons.forEach((button) => {
      button.addEventListener("click", this.onButtonClick.bind(this));
    });
  }

  onButtonClick() {
    setTimeout(
      () => {
        this.showModal("#modal-loading");
      },
      this.timeoutValue ? 1000 : 0
    );
  }

  showModal(modalSelector) {
    const modal = document.querySelector(modalSelector);

    modal.querySelector("h3").textContent = this.titleValue;

    if (modal) {
      window.HSOverlay.open(modalSelector);
      this.modalOpen = true;
    }
  }

  closeModal(modalSelector) {
    if (this.modalOpen) {
      window.HSOverlay.close(modalSelector);
      this.modalOpen = false;
    }
  }
}
