import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.target);
    const selectedValues = Array.from(formData.getAll("fieldset-checkbox"));

    localStorage.setItem("selectedMemories", JSON.stringify(selectedValues));

    const url = this.element.dataset.redirectUrl + `?selectedMemory=${selectedValues[0]}`;

    window.location.href = url;
  }
}
