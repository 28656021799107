import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.onButtonClick);
  }

  onButtonClick(event) {
    const spinnerElement = event.currentTarget.querySelector("[name='spinner']");

    if (spinnerElement) {
      const icon = event.currentTarget.querySelector("svg");

      icon?.classList.toggle("hidden");
      spinnerElement.classList.toggle("hidden");
    }
  }
}
